/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import uniq from 'lodash/uniq';
import { useStaticQuery, graphql } from 'gatsby';

export const Seo = ({
    description = '',
    lang = 'en',
    meta = [],
    keywords = [],
    title,
    image
}) => {
    const { site } = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
              title
              description
              keywords
              author,
              siteUrl
            }
          }
        }
      `
    );

    const metaDescription = description || site.siteMetadata.description;
    const metaKeywords = uniq([
        ...(site.siteMetadata.keywords ?? []),
        ...keywords
    ]);

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: 'description',
                    content: metaDescription
                },
                ...(
                    metaKeywords.length > 0 ?
                        [{
                            name: 'keywords',
                            content: metaKeywords.join(', ')
                        }] :
                        []
                ),
                {
                    property: 'author',
                    content: site.siteMetadata.author
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: metaDescription
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                ...(
                    image ?
                        [{
                            property: 'og:image',
                            content: image
                        }] :
                        []
                ),
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: metaDescription
                },
                ...meta
            ]}
        />
    );
};
