import React from 'react';
import styled from 'styled-components';

const Table = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    &:last-child {
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray}`};
    }
`;

const Cell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    border-bottom: 0;
    padding: 5px;

    &:nth-child(1) {
        flex: 1;
        border-right: 0;
        background-color: ${({ theme }) => theme.colors.gray};
    }

    &:nth-child(2) {
        flex: 2;
    }
`;

export const RecipeSummaryTable = ({ children }) => (
    <Table>
        {
            children.map(row => (
                <Row>
                    {row.props.children.split(':').map(
                        column => (
                            <Cell>
                                {column.trim()}
                            </Cell>
                        )
                    )}
                </Row>
            ))
        }
    </Table>
);
