import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Sidebar } from './sidebar';
import { ThemeProvider } from './theme';
import { RecipeSummary } from './recipe-summary';
import { RecipeSummaryTable } from './recipe-summary-table';
import '../styles/main.scss';
import '../styles/fonts/font-awesome/css/font-awesome.min.css';

const Wrapper = styled.div`
    max-width: 1250px;
    position: relative;
`;

export const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        author
                        description
                        social {
                            facebook
                            instagram
                            email
                        }
                    }
                }
            }
        `}
        render={data => (
            <MDXProvider
                components={{
                    RecipeSummary,
                    RecipeSummaryTable
                }}
            >
                <ThemeProvider>
                    <Wrapper>
                        <Helmet>
                            <link
                                href='https://fonts.googleapis.com/css?family=Lato|PT+Serif&display=swap'
                                rel='stylesheet'
                            />
                        </Helmet>
                        <Sidebar siteMetadata={data.site.siteMetadata} />
                        {children}
                    </Wrapper>
                </ThemeProvider>
            </MDXProvider>
        )}
    />
);
