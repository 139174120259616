/* Medium Devices, Desktops */
export const forMediumDevices = style => `
    @media only screen and (max-width: 992px) {
        ${style};
    }
`;

/* Small Devices, Tablets */
export const forSmallDevices = style => `
    @media only screen and (max-width: 768px) {
        ${style};
    }
`;

/* Extra Small Devices, Phones */
export const forExtraSmallDevices = style => `
    @media only screen and (max-width: 480px) {
        ${style};
    }
`;
