import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const theme = {
    colors: {
        bodyColor: '#515151',
        white: '#ffffff',
        lightGray: '#fafafa',
        gray: '#ecf0f1',
        darkGray: '#a0a0a0',
        darkBlue: '#263959',
        dark: '#333030'
    }
};

export const ThemeProvider = ({ children }) => (
    <StyledThemeProvider theme={theme}>
        {children}
    </StyledThemeProvider>
);
