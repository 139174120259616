import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { forSmallDevices } from '../utils/styles';
import Me from '../assets/me.jpg';

const SidebarRoot = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    height: 100%;
    padding: 20px 10px;
    background-color: ${({ theme }) => theme.colors.white};

    ${forSmallDevices(`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 0 1px 1px 0 rgba(31, 35, 46, 0.15);
    `)}
`;

const About = styled.div`
    margin: 0 0 40px;
    text-align: center;

    ${forSmallDevices(`
        text-align: center;
        max-width: 480px;
        margin: 10px auto auto;
    `)}
`;

const AuthorImageCircle = styled.div`
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
    border-radius: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.dark};
`;

const AuthorImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: transform 0.35s;

    &:hover {
        transform: scale3d(0.90,0.90,1);
    }

    ${forSmallDevices(`
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin-bottom: 10px;
    `)}
`;

const AuthorName = styled.div`
    font-family: 'PT Serif', serif;
    margin: 0 0 10px;
    position: relative;
    padding-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background-color: ${({ theme }) => theme.colors.bodyColor};
    }
`;

const Description = styled.p`
    font-size: 16px;
    margin: 0 0 10px;
`;

const ContactTitle = styled.h3`
    position: relative;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 5px;
    text-transform: uppercase;
    text-align: center;

    &::before {
        content: "";
        display: block;
        height: 2px;
        width: calc(50% - 48px);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 0;
        background-color: ${({ theme }) => theme.colors.bodyColor};
    }

    &::after {
        content: "";
        display: block;
        height: 2px;
        width: calc(50% - 48px);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 0;
        background-color: ${({ theme }) => theme.colors.bodyColor};
    }

    ${forSmallDevices(`
        display: none;
    `)}
`;

const ContactChannels = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
`;

const ContactChannel = styled.li`
    display: inline-block;
    margin-left: 10px;

    &:first-child {
        margin-left: 0;
    }
`;

const ContactChannelLink = styled.a`
    color: ${({ theme }) => theme.colors.bodyColor};
    display: block;
    padding: 5px;
    font-size: 18px;
    transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82);

    &:hover {
        color: ${({ theme }) => theme.colors.dark};
        transform: scale(1.2);
    }
`;

const Copyright = styled.div`
    font-size: 14px;
    text-align: center;
    margin: 0;

    ${forSmallDevices(`
        display: none;
    `)}
`;

const Footer = styled.footer`
    ${forSmallDevices(`
        margin-bottom: 10px;
    `)}
`;

export const Sidebar = ({ siteMetadata }) => (
    <SidebarRoot>
        <header>
            <About>
                <AuthorImageCircle>
                    <Link to='/'>
                        <AuthorImage src={Me} alt={siteMetadata.author} />
                    </Link>
                </AuthorImageCircle>
                <AuthorName>{siteMetadata.author}</AuthorName>
                <Description>{siteMetadata.description}</Description>
            </About>
        </header>
        <Footer>
            <section>
                <ContactTitle>Contact</ContactTitle>
                <ContactChannels>
                    {siteMetadata.social.facebook && (
                        <ContactChannel>
                            <ContactChannelLink
                                href={`https://facebook.com/${siteMetadata.social.facebook}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {/* eslint-disable-next-line react/forbid-dom-props */}
                                <i className='fa fa-facebook' aria-hidden='true' />
                            </ContactChannelLink>
                        </ContactChannel>
                    )}
                    {siteMetadata.social.instagram && (
                        <ContactChannel>
                            <ContactChannelLink
                                href={`https://instagram.com/${siteMetadata.social.instagram}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {/* eslint-disable-next-line react/forbid-dom-props */}
                                <i className='fa fa-instagram' aria-hidden='true' />
                            </ContactChannelLink>
                        </ContactChannel>
                    )}
                    {siteMetadata.social.email && (
                        <ContactChannel>
                            <ContactChannelLink
                                href={`mailto:${siteMetadata.social.email}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {/* eslint-disable-next-line react/forbid-dom-props */}
                                <i className='fa fa-envelope-o' aria-hidden='true' />
                            </ContactChannelLink>
                        </ContactChannel>
                    )}
                </ContactChannels>
            </section>
            <Copyright>
                <p>
                    {new Date().getFullYear()}
                    {' '}
                    &copy;
                    {' '}
                    {siteMetadata.author}
                </p>
            </Copyright>
        </Footer>
    </SidebarRoot>
);
